import { osiris } from '../api'

class GroupsOsirisService {
    api = osiris

    async getAllGroups() {
      try {
        return await this.api.get('group')
      } catch ({ response }) {
        return response
      }
    }

    async getAllUsers() {
      try {
        return await this.api.get('user')
      } catch ({ response }) {
        return response
      }
    }

    async getGroupById(id) {
      try {
        return await this.api.get(`/group/${id}`)
      } catch ({ response }) {
        return response
      }
    }

    async getUserById(id) {
      try {
        return await this.api.get(`/user/${id}`)
      } catch ({ response }) {
        return response
      }
    }

    async store(request) {
      try {
        return await this.api.post('/group', request)
      } catch ({ response }) {
        return response
      }
    }

    async storeUser(request) {
      try {
        return await this.api.post('/user', request)
      } catch ({ response }) {
        return response
      }
    }

    async updateUser(request, id) {
      try {
        return await this.api.put(`/user/${id}`, request)
      } catch ({ response }) {
        return response
      }
    }

    async update(request, id) {
      try {
        return await this.api.put(`/group/${id}`, request)
      } catch ({ response }) {
        return response
      }
    }
}

export default new GroupsOsirisService()
