<template>
  <b-container class="d-flex justify-content-center">
    <b-col md="8">
      <b-card :title="id ? 'Editar Usuário' : 'Criar Usuário'">
        <validation-observer
          #default="{invalid}"
          ref="simpleRules"
        >
          <user-form
            :group-options="groupOptions"
            :data="request"
          />
          <b-row>
            <b-col>
              <b-button
                style="width: 100%;"
                variant="primary"
                :disabled="invalid"
                @click="createGroup"
              >
                {{ id ? 'Editar' : 'Criar' }}
              </b-button>
            </b-col>

            <b-col>
              <b-button
                style="width: 100%;"
                variant="primary"
                :to="{ name: 'group' }"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import {
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BContainer,
    BCol,
    BRow,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';
import '@validations';

import GroupService from '@/service/credit_platform/groups';

import UserForm from './components/UserForm.vue';
import { callLoading } from '@/service/utilities';

const permissionsOptions = [
    { value: 1, text: 'Administrador' },
    { value: 2, text: 'Cadastrar Usuários' },
    { value: 3, text: 'Cadastrar Estabelecimentos' },
    { value: 4, text: 'Criar Opt Ins' },
    { value: 5, text: 'Atualizar Opt Ins' },
    { value: 6, text: 'Consulta Opt Ins' },
    { value: 7, text: 'Rejeitar Opt Ins' },
    { value: 8, text: 'Criar Opt Out' },
    { value: 9, text: 'Criar Averbações' },
    { value: 10, text: 'Atualizar Avebações' },
    { value: 11, text: 'Buscar Averbações' },
    { value: 12, text: 'Consulta Agenda' },
    { value: 13, text: 'Visualizar Estatísticas' },
    { value: 14, text: 'Visualizar Extrato' },
    { value: 15, text: 'Abrir Contestação' },
    { value: 16, text: 'Realizar Conciliação' },
    { value: 17, text: 'Consultar Efeitos de Contrato' },
    { value: 18, text: 'Cadastrar Grupos' },
].sort((a, b) => a.text.localeCompare(b.text));

export default {
    components: {
        BContainer,
        BCard,
        BCol,
        ValidationObserver,
        BButton,
        BRow,
        UserForm,
    },

    data() {
        return {
            request: { permissions: [14] },
            id: this.$route.params.id,
            permissionsOptions,
            groupOptions: [],
            allEmailsRegistred: [],
        };
    },

    async mounted() {
        const promises = [
            GroupService.getAllGroups(),
        ];

        if (this.id) {
            promises.push(GroupService.getUserById(this.id));
        }

        const response = await Promise.all(
            promises,
        );

        const [
            { data: { data: group } },
            userResponse,
        ] = response;

        this.groupOptions = group.map(item => ({
            value: item.id,
            title: item.name,
        }));

        if (userResponse) {
            const { data: userData } = userResponse;
            this.request = {
                ...userData,
                id: this.id,
                group: this.groupOptions.find(item => item.value === userData.group_id),
                master_user: !!userData.master_user,
            };
        }
    },

    methods: {
        modalError(text) {
            this.$swal({
                icon: 'error',
                text,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },

        modalSuccess(text) {
            this.$swal({
                icon: 'success',
                text,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },
        async confirmAnAction(message) {
            try {
                const response = await this.$bvModal
                    .msgBoxConfirm(message, {
                        cancelVariant: 'outline-secondary',
                        centered: true,
                        okTitle: 'Sim',
                        cancelTitle: 'Não',
                    });
                return response;
            } catch {
                return false;
            }
        },
        async createGroup() {
            const isConfirmed = await this.confirmAnAction('Você realmente deseja criar esse usuário');

            if (!isConfirmed) {
                return;
            }
            callLoading(true);
            const {
                name, email, permissions, master_user, group,
            } = this.request;

            const request = {
                name, email, permissions, master_user,
            };

            if (group) {
                request.group_id = group.value;
            }
            const { status } = await (this.id ? GroupService.updateUser(request, this.id) : GroupService.storeUser(request));

            callLoading(false);

            if (status === 200) {
                this.modalSuccess(`Usuário ${this.id ? 'editado' : 'criado'} com sucesso`);
                this.$router.push('/plataforma-credito/grupo');
                this.$nextTick(() => this.$refs.simpleRules.reset());
            } else {
                this.modalError('Não foi possível criar esse usuário');
            }
        },
    },
};
</script>
