<template>
  <b-form
    class="needs-validation"
    @submit.prevent
  >
    <b-form-group
      label="Nome"
      label-for="name"
    >
      <validation-provider
        #default="{ errors }"
        name="Nome"
        rules="required"
      >

        <b-form-input
          id="name"
          v-model="data.name"
          :state="errors.length > 0 ? false:null"
          placeholder="Nome"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Email"
      label-for="email"
    >
      <validation-provider
        #default="{ errors }"
        name="Email"
        rules="required|email"
      >

        <b-form-input
          id="email"
          v-model="data.email"
          :state="errors.length > 0 ? false:null"
          placeholder="Email"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Grupo"
      label-for="group"
    >
      <validation-provider
        #default="{ errors }"
        name="Grupo"
        rules="required"
      >

        <v-select
          v-model="data.group"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Selecione um Grupo"
          :disabled="data.id"
          label="title"
          :options="groupOptions"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label=""
      label-for="group"
    >
      <validation-provider
        #default="{ errors }"
        name="Grupo"
        rules="required"
      >

        <b-form-checkbox-group
          v-model="data.permissions"
          stacked
          :options="permissionsOptions"
          switches
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <div class="mb-1">
      <b-form-checkbox
        v-model="data.master_user"
        name="check-button"
        switch
        inline
      >
        Usuário Master
      </b-form-checkbox>
    </div>

  </b-form>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import vSelect from 'vue-select'
import GroupService from '@/service/credit_platform/groups'

import '@validations'

const permissionsOptions = [
  { value: 1, text: 'Administrador' },
  { value: 2, text: 'Cadastrar Usuários' },
  { value: 3, text: 'Cadastrar Estabelecimentos' },
  { value: 4, text: 'Criar Opt Ins' },
  { value: 5, text: 'Atualizar Opt Ins' },
  { value: 6, text: 'Consulta Opt Ins' },
  { value: 7, text: 'Rejeitar Opt Ins' },
  { value: 8, text: 'Criar Opt Out' },
  { value: 9, text: 'Criar Averbações' },
  { value: 10, text: 'Atualizar Avebações' },
  { value: 11, text: 'Buscar Averbações' },
  { value: 12, text: 'Consulta Agenda' },
  { value: 13, text: 'Visualizar Estatísticas' },
  { value: 14, text: 'Visualizar Extrato' },
  { value: 15, text: 'Abrir Contestação' },
  { value: 16, text: 'Realizar Conciliação' },
  { value: 17, text: 'Consultar Efeitos de Contrato' },
  { value: 18, text: 'Cadastrar Grupos' },
].sort((a, b) => a.text.localeCompare(b.text))

export default {
  components: {
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
    BFormCheckboxGroup,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },

    groupOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      permissionsOptions,
      allEmailsRegistred: [],
    }
  },

}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/new-client.scss";
</style>
