var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Nome"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Grupo","label-for":"group"}},[_c('validation-provider',{attrs:{"name":"Grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Selecione um Grupo","disabled":_vm.data.id,"label":"title","options":_vm.groupOptions},model:{value:(_vm.data.group),callback:function ($$v) {_vm.$set(_vm.data, "group", $$v)},expression:"data.group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"","label-for":"group"}},[_c('validation-provider',{attrs:{"name":"Grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"stacked":"","options":_vm.permissionsOptions,"switches":""},model:{value:(_vm.data.permissions),callback:function ($$v) {_vm.$set(_vm.data, "permissions", $$v)},expression:"data.permissions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-1"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.data.master_user),callback:function ($$v) {_vm.$set(_vm.data, "master_user", $$v)},expression:"data.master_user"}},[_vm._v(" Usuário Master ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }